import React, { Component } from 'react';
import Figure from 'react-bootstrap/Figure';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HygeiaPump from '../assets/images/Hygeia_Express.png';
import SpectraPump from '../assets/images/spectra_s1-pump.png';
import SpectraPumps1 from '../assets/images/SpectraS1.png';
import SpectraPumps2 from '../assets/images/spectra_s2.jpeg';
import MedelaPump from '../assets/images/medela-maxflow.png';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import Alert from 'react-bootstrap/Alert';


export class FormPumpSelectionConfirm extends Component {
    constructor(){
        super();
        this.continue = this.continue.bind(this);
        this.processSpectraPumpPurchase = this.processSpectraPumpPurchase.bind(this);
        this.processMedelaPump = this.processMedelaPump.bind(this);
        window.addEventListener("resize", this.indicatorController);
    }
    componentDidMount(){
        this.indicatorController();

        document.getElementById("indicator-click-action-right").addEventListener("click", function(){
            var wrapperSize =  document.getElementById("productsWrapper").getBoundingClientRect().width;
            var gridSize = document.getElementById("productsGrid").getBoundingClientRect().width;
            var tdSize = document.getElementById("referenceTD").getBoundingClientRect().width;

            var leftover = document.getElementById("productsWrapper").scrollLeft;
            var offset = gridSize-wrapperSize;

            if(offset !== leftover){
                document.getElementById("productsWrapper").scrollLeft += tdSize;
            }else{
                document.getElementById("indicator-click-action-right").classList.add('invisible');
                document.getElementById("indicator-click-action-right").classList.remove('visible');

                document.getElementById("indicator-click-action-left").classList.add('visible');
                document.getElementById("indicator-click-action-left").classList.remove('invisible');
            }


        });
        document.getElementById("indicator-click-action-left").addEventListener("click", function(){
            var tdSize = document.getElementById("referenceTD").getBoundingClientRect().width;

            var leftover = document.getElementById("productsWrapper").scrollLeft;

            if(leftover !== 0){
                document.getElementById("productsWrapper").scrollLeft -= tdSize;                
            }else{
                document.getElementById("indicator-click-action-left").classList.add("invisible")
                document.getElementById("indicator-click-action-left").classList.remove('visible');

                document.getElementById("indicator-click-action-right").classList.add("visible");
                document.getElementById("indicator-click-action-right").classList.remove('invisible');
            }
        });
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.indicatorController);
    }
    indicatorController = () => {
        var wrapperSize =  document.getElementById("productsWrapper").getBoundingClientRect().width;
        var gridSize = document.getElementById("productsGrid").getBoundingClientRect().width;
        if(wrapperSize < gridSize){
            document.getElementById("productsWrapper").classList.add("more");
        }else{
            document.getElementById("productsWrapper").classList.remove("more");
        }
    }
    continue = e => {
        e.preventDefault();
        var pumpSelection = "Other";
        if(this.props.values.chooseHygeia){
            pumpSelection = "Hygeia";
        }
        if(this.props.values.chooseOtherSelection === "Ameda"){
            pumpSelection = "ameda-finesse";
        }
        var data = {
            vid: this.props.values.vid,
            dealId: this.props.values.dealId,
            pump: pumpSelection,
            doctorMike: this.props.values.includeCourse
        };
        axios.post("https://api2.hygeiahealth.com/api/v1/Form/Submit/ConfirmHygeia",data).then((response) => {
            if(!response.data.hasError){
                this.props.values.vid = response.data.vid;
                this.props.values.dealId = response.data.dealId;
                this.props.handleSuccessfulSubmit();
                this.props.nextStep();
            }else{
                //TODO: HANDLE API ERRORS
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    back = e => { 
        e.preventDefault();
        this.props.prevStep();
    }
    processHygeiaPumpSelection = e =>{
        e.preventDefault();
        var pumpSelection = "Hygeia";
        var data = {
            vid: this.props.values.vid,
            dealId: this.props.values.dealId,
            pump: pumpSelection,
            doctorMike: this.props.values.includeCourse
        };
        axios.post("https://api2.hygeiahealth.com/api/v1/Form/Submit/ConfirmHygeia",data).then((response) => {
            if(!response.data.hasError){
                this.props.values.vid = response.data.vid;
                this.props.values.dealId = response.data.dealId;
                this.props.handleSuccessfulSubmit();
                this.props.nextStep();
            }else{
                //TODO: HANDLE API ERRORS
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    processSpectraS2PumpPurchase(token){
        var stripeToken = token.id;
        var data = {
            vid: this.props.values.vid,
            dealId: this.props.values.dealId,
            token: stripeToken,
            amount: 2900,
            pumpType: "spectra"
        };
        axios.post("https://api2.hygeiahealth.com/api/v1/Form/Payments/PumpPurchase",data).then((response) => {
            console.log(response);
            if(!response.data.hasError){
                this.props.nextStep();
            }else{
                //TODO: HANDLE API ERRORS
            }
        });
    }
    processSpectraPumpPurchase(token){
        var stripeToken = token.id;
        var data = {
            vid: this.props.values.vid,
            dealId: this.props.values.dealId,
            token: stripeToken,
            amount: 11900,
            pumpType: "spectra-s1"
        };
        axios.post("https://api2.hygeiahealth.com/api/v1/Form/Payments/PumpPurchase",data).then((response) => {
            console.log(response);
            if(!response.data.hasError){
                this.props.nextStep();
            }else{
                //TODO: HANDLE API ERRORS
            }
        });
    }
    processMedelaPump(token){
        var stripeToken = token.id;
        var data = {
            vid: this.props.values.vid,
            dealId: this.props.values.dealId,
            token: stripeToken,
            amount: 3900,
            pumpType: "medela"
        };
        axios.post("https://api2.hygeiahealth.com/api/v1/Form/Payments/PumpPurchase",data).then((response) => {
            console.log(response);
            if(!response.data.hasError){
                this.props.nextStep();
            }else{
                //TODO: HANDLE API ERRORS
            }
        });
    }
    render() {
        const { values, handleChange, handleDismiss } = this.props;
        
        return (
            <div className={"wrapper-" + (values.domain !== "" ? values.domainStyle : '')}>
                <ProgressBar  now={values.progressPercent} /> 
                <div className="badge badge-primary progressBar-step"> Step {values.step} of 8</div>
                <div className={"submitting " + (this.props.values.submitting ? '': 'hidden')} >
                    <div>
                        <FontAwesomeIcon icon="circle-notch" className="green" size="10x" spin/>
                    </div>
                </div>
                <h1>Confirm your Order 
                    <small className={ !values.chooseHygeia ? 'visible' : 'invisible'}>You have to make a selection below</small>
                </h1>
                <div className={" text-center " + ( values.chooseHygeia ? 'd-block' : 'd-none' )}>
                    <Figure>
                        <Figure.Image
                            className="img-fluid"
                            alt="Hygeia Pump"
                            src={HygeiaPump}
                            width="250"
                            height="250"
                        />
                        <Figure.Caption>
                            Hygeia Hospital-grade Pump
                        </Figure.Caption>
                    </Figure>
                </div>
                <div className={" confirm-section " + ( values.chooseHygeia ? 'd-none' : 'd-block' )}>
                    <div id="productsWrapper" className="scrolling-table">
                    <Table id="productsGrid" className={"pricing-table compare-table " + (values.chooseOtherSelection)}>
                            <thead>
                                <tr>
                                    <th className="border-0 headcol"> </th>
                                    <th className="border-top-0 headcol-2">
                                        <h5>Hygeia Express</h5>
                                    </th>
                                    
                                    <th className="border-top-0">
                                        <h5>Medela MaxFlow</h5>
                                    </th>
                                    <th className="border-top-0">
                                        <h5>Spectra S2</h5>
                                    </th>
                                    <th className="border-top-0">
                                        <h5>Spectra S1</h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border-0 headcol"> </td>
                                    <td id="referenceTD" className={ " space-column  headcol-2 p-2 " + (values.chooseOtherSelection==='Hygeia' ? 'selected' : '')}>
                                        <Figure>
                                            <Figure.Image
                                                className="img-fluid"
                                                alt="Hygeia Pump"
                                                src={HygeiaPump}
                                                width="250"
                                                height="250"
                                            />
                                        </Figure>
                                    </td>
                                    
                                    <td className={"  space-column p-2 " + (values.chooseOtherSelection==='Medela' ? 'selected' : '')}>
                                        <Figure>
                                            <Figure.Image
                                                className="img-fluid"
                                                alt="Medela Pump"
                                                src={MedelaPump}
                                            />
                                        </Figure>    
                                
                                    </td>
                                    <td className={"  space-column p-2 " + (values.chooseOtherSelection==='Spectra' ? 'selected' : '')}>
                                        <Figure>
                                            <Figure.Image
                                                className="img-fluid"
                                                alt="Spectra S2 Pump"
                                                src={SpectraPumps2}
                                            />

                                        </Figure>                               
                                    </td>
                                    <td className={"  space-column p-2 " + (values.chooseOtherSelection==='Spectra' ? 'selected' : '')}>
                                        <Figure>
                                            <Figure.Image
                                                className="img-fluid"
                                                alt="Spectra S2 Pump"
                                                src={SpectraPumps1}
                                            />

                                        </Figure>                               
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-0 headcol'> Performance Grade</td>
                                    <td className="green headcol-2">Hospital</td>
                                    <td>Personal</td>
                                    <td>Personal</td>
                                    <td>Personal</td>
                                </tr>
                                <tr>
                                    <td className='border-0 headcol'> Double Electric Pump </td>
                                    <td className="headcol-2">
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-0 headcol'> Wearable Cups Included</td>
                                    <td className="headcol-2">
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-0 headcol'> Cordless</td>
                                    <td className="headcol-2">
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-0 headcol'> Free Breastfeeding Consultations	</td>
                                    <td className="headcol-2">
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-0 headcol'> Free Extra Collection Cups & Accessories</td>
                                    <td className="headcol-2">
                                        <FontAwesomeIcon icon="check" className="green" size="lg" />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon="times" className="grey" size="lg"/>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <td className="border-0 headcol">Pump Weight</td>
                                    <td className="headcol-2">0.95 lbs</td>
                                    <td>1.2 lbs</td>
                                    <td>3.0 lbs</td>
                                    <td>3.0 lbs</td>
                                </tr>
                                <tr>
                                    <td className="border-0 headcol">
                                        <strong> Make a selection</strong>
                                    </td>
                                    <td className="headcol-2">
                                        <span className="Hygeia Pump-free">
                                            <Button 
                                                variant="success"
                                                value="Hygeia"
                                                name="chooseOtherSelection"
                                                size="lg"             
                                                onClick = {this.processHygeiaPumpSelection}
                                                block
                                                >
                                                <strong> 
                                                    <span>FREE</span>
                                                </strong>  
                                            </Button> 
                                        </span>
                                    </td>
                                    <td>
                                        <span className="Medela">
                                            <StripeCheckout
                                                name={this.props.values.domainName}
                                                description="Medela Pump"
                                                label="$39"
                                                image="https://hygeiahealth.wpengine.com/wp-content/uploads/2016/02/hg-logo.png"
                                                panelLabel="Purchase Medela Pump"
                                                amount={3900}
                                                currency="USD"
                                                stripeKey="pk_live_XZ7rBghf4y1yRmU5rhISIaZi"
                                                token={this.processMedelaPump}
                                            />   
                                        </span>
                                    </td>
                                    <td>
                                        <span className="Spectra">
                                            <StripeCheckout
                                                name={this.props.values.domainName}
                                                description="Spectra Pump"
                                                label="$29"
                                                image="https://hygeiahealth.wpengine.com/wp-content/uploads/2016/02/hg-logo.png"
                                                panelLabel="Purchase Spectra Pump"
                                                amount={2900}
                                                currency="USD"
                                                stripeKey="pk_live_XZ7rBghf4y1yRmU5rhISIaZi"
                                                token={this.processSpectraS2PumpPurchase}
                                            />    
                                        </span>
                                    </td>
                                    <td>
                                        <span className="Spectra">
                                            <StripeCheckout
                                                name={this.props.values.domainName}
                                                description="Spectra Pump"
                                                label="$119"
                                                image="https://hygeiahealth.wpengine.com/wp-content/uploads/2016/02/hg-logo.png"
                                                panelLabel="Purchase Spectra Pump"
                                                amount={11900}
                                                currency="USD"
                                                stripeKey="pk_live_XZ7rBghf4y1yRmU5rhISIaZi"
                                                token={this.processSpectraPumpPurchase}
                                            />    
                                        </span>
                                    </td>

                                </tr>
                                
                            </tbody>
                        </Table>                            
                        
                    </div>
                    <div id="indicator-click-action-right" className="indicator">
                            <FontAwesomeIcon icon="chevron-right" size="2x"/>
                    </div>
                    <div id="indicator-click-action-left" className="indicator">
                            <FontAwesomeIcon icon="chevron-left" size="2x"/>
                    </div>
                </div>
                <Row>
                    <Col className="text-right border-top pt-3 pb-3 col"> 
                        <Button variant="default" type="button" onClick={this.back}>
                                Previous 
                        </Button>
                        <Button 
                            variant="primary" 
                            type="button" 
                            onClick={this.continue} 
                            disabled={!values.chooseHygeia}  
                            className={(!values.chooseHygeia ? 'd-none' : '')}>
                                Confirm
                        </Button>
                    </Col>
                </Row>
                <Alert variant="danger" show={values.apiError}>
                    <button type="button" className="close"  onClick={handleDismiss}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <span>{values.apiErrorMessage}</span>
                </Alert>                
            </div>
        )
    }
}

export default FormPumpSelectionConfirm
